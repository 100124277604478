@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Public Sans';
  src: url('../assets/fonts/public-sans/PublicSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
