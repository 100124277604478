/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@import "styles/fonts.scss";
@import "styles/daimler-fonts.scss";

@layer base {
  :root {
    --color-primary: rgb(0, 0, 0);
    --color-secondary: rgb(255, 255, 255);
    --color-success: #28a745;
    --color-info: #17a2b8;
    --color-warning: #ffc107;
    --color-error: #ff0000;
  }

  html, body {
    @apply m-0 p-0 bg-[#0a0a0a] text-white h-full;
    font-family: "Public Sans", system-ui, sans-serif;
  }

  router-outlet + * {
    @apply flex flex-col h-full;
  }

  a {
    @apply text-cyan hover:text-cyan-highlight;
  }
}

@layer components {
  .btn {
    &-global {
      @apply font-medium text-sm text-center focus:outline-none;
    }

    &-primary {
      @apply btn-global bg-cyan text-black enabled:hover:bg-cyan-highlight disabled:opacity-50 focus:ring-1 focus:ring-cyan-highlight;
    }

    &-secondary {
      @apply btn-global bg-white/10 text-white enabled:hover:text-cyan disabled:opacity-50;
    }

    &-transparent {
      @apply btn-global bg-transparent;

      &-primary {
        @apply btn-transparent enabled:text-cyan disabled:text-cyan/50 enabled:hover:bg-[#1b4e59]/30 disabled:bg-[#1b4e59]/10;
      }

      &-secondary {
        @apply btn-transparent enabled:text-white disabled:text-white/50 enabled:hover:bg-white/10;
      }
    }

    &-error {
      @apply btn-global bg-transparent enabled:text-white disabled:bg-error-disabled enabled:hover:bg-error-highlight focus:ring-1 focus:ring-error-highlight enabled:hover:text-error;
    }

    &-warning {
      @apply btn-global bg-transparent enabled:text-white disabled:bg-warning-disabled enabled:hover:bg-warning-highlight focus:ring-1 focus:ring-warning-highlight enabled:hover:text-warning;
    }

    &-success {
      @apply btn-global bg-transparent enabled:text-white disabled:bg-success-disabled enabled:hover:bg-success-highlight focus:ring-1 focus:ring-success-highlight enabled:hover:text-success;
    }
  }

  .alert {
    &-screen-fixed {
      @apply fixed w-full md:w-1/2 xl:w-1/3 3xl:w-1/4 max-h-[32rem] bottom-0 md:bottom-1/2 left-0 md:left-1/2 md:-translate-x-1/2 -translate-y-0 md:translate-y-1/2 z-30 overflow-auto md:overflow-visible;
    }
  }

  .modal {
    @apply fixed isolate top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-h-fit max-h-[90vh] max-w-[95vw] flex flex-col z-[100] bg-transparent-dark rounded-t-10 rounded-b-10 shadow-[0_30px_60px_#00000066];

    &-small {
      @apply w-[90vw] md:w-[60vw] xl:w-[45vw];
      /* Height based on 16:9 ratio */
      aspect-ratio: 16/9;
      /* Ensure minimum height on different screens while maintaining aspect ratio when possible */
      min-height: min(50.625vw, 90vh); /* 90vw * 9/16 for small screens */
      @screen md {
        min-height: min(33.75vw, 90vh); /* 60vw * 9/16 for medium screens */
      }
      @screen xl {
        min-height: min(25.3125vw, 90vh); /* 45vw * 9/16 for large screens */
      }
    }

    &-medium {
      @apply w-[95vw] md:w-[75vw] xl:w-[60vw];
      /* Height based on 16:9 ratio */
      aspect-ratio: 16/9;
      /* Ensure minimum height on different screens while maintaining aspect ratio when possible */
      min-height: min(53.4375vw, 90vh); /* 95vw * 9/16 for small screens */
      @screen md {
        min-height: min(42.1875vw, 90vh); /* 75vw * 9/16 for medium screens */
      }
      @screen xl {
        min-height: min(33.75vw, 90vh); /* 60vw * 9/16 for large screens */
      }
    }

    &-large {
      @apply w-[95vw] md:w-[85vw] xl:w-[75vw];
      /* Height based on 16:9 ratio */
      aspect-ratio: 16/9;
      /* Ensure minimum height on different screens while maintaining aspect ratio when possible */
      min-height: min(53.4375vw, 90vh); /* 95vw * 9/16 for small screens */
      @screen md {
        min-height: min(47.8125vw, 90vh); /* 85vw * 9/16 for medium screens */
      }
      @screen xl {
        min-height: min(42.1875vw, 90vh); /* 75vw * 9/16 for large screens */
      }
    }

    &-header {
      @apply h-8 flex justify-between items-center absolute w-full;
    }

    &-title {
      @apply font-semibold text-base tracking-wide text-ellipsis text-nowrap whitespace-nowrap overflow-hidden;
    }

    &-close-button {
      @apply w-4 h-4 z-40;
    }

    &-content {
      @apply flex-grow overflow-auto max-h-full h-full w-full;
    }

    &-backdrop {
      @apply fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-[99];
    }

    &-overlay {
      @apply fixed inset-0 z-[99] flex items-center justify-center overflow-auto;
    }
  }

  .tooltip {
    @apply absolute min-w-[4rem] min-h-[20px] mt-2 top-full transform left-1/2 -translate-x-1/2 rounded z-50;

    &-peer {
      @apply opacity-0 peer-hover:opacity-100 scale-0 peer-hover:scale-100 transition-opacity transition-transform;
    }

    &-group {
      @apply opacity-0 group-hover:opacity-100 scale-0 group-hover:scale-100 transition-opacity transition-transform;
    }
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
