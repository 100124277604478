 /* Daimler Fonts */
 @font-face {
  font-family: 'MBCorpoAScreen1Cond-Regular';
  src: url('~assets/fonts/Daimler/MBCorpoAScreen1Cond-Regular-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoAScreen1Cond-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoAScreen1Cond-Regular';
  src: url('~assets/fonts/Daimler/MBCorpoAScreen1Cond-Regular-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoAScreen1Cond-Regular.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'MBCorpoAScreen2Cond-Regular';
  src: url('~assets/fonts/Daimler/MBCorpoAScreen2Cond-Regular-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoAScreen2Cond-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoATextCond-Regular';
  src: url('~assets/fonts/Daimler/MBCorpoATextCond-Regular-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoATextCond-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoATitleCond-Regular';
  src: url('~assets/fonts/Daimler/MBCorpoATitleCond-Regular-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoATitleCond-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoATitle-Regular';
  src: url('~assets/fonts/Daimler/MBCorpoATitle-Regular-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoATitle-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSText-Bold';
  src: url('~assets/fonts/Daimler/MBCorpoSText-Bold-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSText-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSText-CondBold';
  src: url('~assets/fonts/Daimler/MBCorpoSText-CondBold-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSText-CondBold.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSText-CondLight';
  src: url('~assets/fonts/Daimler/MBCorpoSText-CondLight-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSText-CondLight.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSText-CondRegular';
  src: url('~assets/fonts/Daimler/MBCorpoSText-CondRegular-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSText-CondRegular.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSText-Light';
  src: url('~assets/fonts/Daimler/MBCorpoSText-Light-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSText-Light.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSText-Regular';
  src: url('~assets/fonts/Daimler/MBCorpoSText-Regular-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSText-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSTitle-Bold';
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-Bold-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSTitle-CondBold';
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-CondBold-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-CondBold.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSTitle-CondLight';
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-CondLight-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-CondLight.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSTitle-CondRegular';
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-CondRegular-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-CondRegular.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSTitle-Light';
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-Light-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-Light.otf') format('opentype');
}

@font-face {
  font-family: 'MBCorpoSTitle-Regular';
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-Regular-Web.ttf') format('truetype');
  src: url('~assets/fonts/Daimler/MBCorpoSTitle-Regular.otf') format('opentype');
}